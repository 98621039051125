// src/components/CustomerView.js
import React, { useEffect, useState } from "react";
import { fetchStudentsByCustomerNum } from "../../api/studentApi";
import "./CustomerView.css"; // CSS specific to this component
const API_URL = process.env.REACT_APP_API_URL;

const CustomerView = ({ customerID, onBack }) => {
  const [customer, setCustomer] = useState(null);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomerAndStudents = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Authentication token is missing");
        }
        // Fetch customer details
        const responseCustomer = await fetch(
          `${API_URL}/api/customers/find?customerID=${customerID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!responseCustomer.ok) {
          throw new Error("Customer not found");
        }
        const customerData = await responseCustomer.json();
        setCustomer(customerData);

        // Fetch student details linked to this customer
        const responseStudents = await fetchStudentsByCustomerNum(
          customerData.CustomerID
        );

        setStudents(responseStudents);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (customerID) {
      fetchCustomerAndStudents();
    }
  }, [customerID]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!customer) {
    return null;
  }

  return (
    <div className="customer-view-wrapper">
      <button className="customer-view-back-button" onClick={onBack}>
        Back
      </button>
      <div className="customer-view-container">
        <div className="customer-info">
          <h2>Customer Details</h2>
          <p>
            <strong>Customer ID:</strong> {customer.CustomerID}
          </p>
          <p>
            <strong>Primary Name:</strong> {customer.primary_first_name}{" "}
            {customer.primary_last_name}
          </p>
          {customer.sec_first_name && (
            <p>
              <strong>Secondary Contact:</strong> {customer.sec_first_name}{" "}
              {customer.sec_last_name} ({customer.sec_rel})
            </p>
          )}
          <p>
            <strong>Address:</strong> {customer.street_num}{" "}
            {customer.street_name}, {customer.city}, {customer.prov_code},{" "}
            {customer.postal_code}, {customer.country}
          </p>
          <p>
            <strong>Home Phone:</strong> {customer.home_phone}
          </p>
          <p>
            <strong>Cell Phone:</strong> {customer.cell_phone}
          </p>
          {customer.sec_phone && (
            <p>
              <strong>Secondary Phone:</strong> {customer.sec_phone}
            </p>
          )}
          <p>
            <strong>Email:</strong> {customer.email_address}
          </p>
          {customer.sec_email_address && (
            <p>
              <strong>Secondary Email:</strong> {customer.sec_email_address}
            </p>
          )}
          <p>
            <strong>How Found:</strong> {customer.how_found}
          </p>
          <p>
            <strong>QBO Status:</strong> {customer.qbo_boolean ? "Yes" : "No"}
          </p>
          {customer.qbo_date && (
            <p>
              <strong>QBO Date:</strong>{" "}
              {new Date(customer.qbo_date).toLocaleDateString()}
            </p>
          )}
          <p>
            <strong>Comments:</strong> {customer.comment}
          </p>
          <p>
            <strong>Rep ID:</strong> {customer.rep_id}
          </p>
        </div>

        {/* Student Information */}
        {students.length > 0 && (
          <div className="student-info">
            <h2>Student Details</h2>
            {students.map((student, index) => (
              <div key={student._id} className="student-details">
                <p>
                  <strong>Student Name:</strong> {student.first_name}{" "}
                  {student.last_name}
                </p>
                <p>
                  <strong>Student Number:</strong> {student.student_num}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerView;
