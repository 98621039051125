// src/components/StudentSearch.js
import React, { useState } from "react";
import { fetchStudentByNumber } from "../../api/studentApi";
import { fetchClassesByStudentId } from "../../api/classApi";
import {
  fetchLegacyReportCardTemplates,
  fetchReport,
} from "../../api/reportApi";
import "./StudentSearch.css";
import { generateLegacyReportCardPDF } from "../../pdf/generateLegacyReportCard"; // Import the PDF generation function
import { generateReportPDF } from "../../pdf/generateReport"; // Import the PDF generation function

const StudentSearch = () => {
  const [studentNum, setStudentNum] = useState("");
  const [studentData, setStudentData] = useState(null);
  const [classesData, setClassesData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportCardTemplates, setReportCardTemplates] = useState([]);
  const [error, setError] = useState("");

  const handleSearch = async () => {
    try {
      const data = await fetchStudentByNumber(studentNum);
      setStudentData(data);
      setError(null);
      const classes = await fetchClassesByStudentId(data._id);
      setClassesData(classes);
      const templates = await fetchLegacyReportCardTemplates();
      setReportCardTemplates(templates);
      const reports = await fetchReport(data._id);
      setReportData(reports);
    } catch (error) {
      setError(error.message);
      setStudentData(null);
      setClassesData([]);
      setReportData([]);
    }
  };

  // Function to handle PDF generation
  const handleDownloadReportCard = (reportCardID) => {
    const reportCard = studentData.report_cards.find(
      (rc) => rc._id === reportCardID
    );
    const matchedTemplate = reportCardTemplates.find(
      (template) => template.course._id === reportCard.course._id
    );
    if (matchedTemplate) {
      generateLegacyReportCardPDF(reportCard, matchedTemplate); // Generate the PDF
    } else {
      console.error("No matching template found for the report card.");
    }
  };

  const handleDownloadNewReport = (reportCardID) => {
    const reportCard = reportData.find((rc) => rc._id === reportCardID);
    generateReportPDF(reportCard);
    console.log(reportCard);
  };

  return (
    <div className="student-search-container">
      <h2 className="student-search">Student Search</h2>
      <input
        type="text"
        placeholder="Enter student_num"
        value={studentNum}
        onChange={(e) => setStudentNum(e.target.value)}
        className="student-search-input"
      />
      <button className="student-search-button" onClick={handleSearch}>
        Search
      </button>
      <hr />
      {error && <p className="error-message">{error}</p>}
      {studentData && (
        <div className="student-view-info">
          <h3>
            Student Name: {studentData.first_name} {studentData.last_name}
          </h3>
          <p>
            <strong>Student Number:</strong> {studentData.student_num}
          </p>

          {studentData.next_recommended_course &&
            studentData.next_recommended_course.length > 0 && (
              <div className="next-recommended">
                <strong>Next Course Recommendations:</strong>
                <ul>
                  {studentData.next_recommended_course.map((course, index) => (
                    <li key={index}>{course.name}</li>
                  ))}
                </ul>
              </div>
            )}

          <hr />
          <div className="courses-taken">
            <h4>Courses Taken:</h4>
            {classesData && classesData.length > 0 ? (
              <ul>
                {classesData
                  .sort((a, b) => {
                    if (a.season > b.season) return -1;
                    if (a.season < b.season) return 1;
                    return 0;
                  })
                  .map((courseClass, index) => (
                    <li key={index}>
                      <strong>Course Name:</strong>{" "}
                      {courseClass.course?.name || "Not Assigned"}
                      <br />
                      <strong>Instructor:</strong>{" "}
                      {courseClass.instructor?.name || "Not Assigned"}
                      <br />
                      <strong>Season:</strong>{" "}
                      {courseClass.season || "Not Assigned"}
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No courses found for this student.</p>
            )}
          </div>
          <hr />
          <div className="student-comments">
            <h4>Student Comments:</h4>
            {studentData.student_comments &&
            studentData.student_comments.length > 0 ? (
              <ul>
                {studentData.student_comments
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((comment, index) => (
                    <li key={index}>
                      {comment.comment} (Date:{" "}
                      {new Date(comment.date).toLocaleDateString()} )
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No comments found for this student.</p>
            )}
          </div>
          <hr />
          <div className="instructor-comments">
            <h4>Report Cards:</h4>
            {(studentData.report_cards &&
              studentData.report_cards.length > 0) ||
            (reportData && reportData.length > 0) ? (
              <ul>
                {[
                  // Normalize legacy report cards
                  ...(studentData.report_cards || []).map((legacyReport) => ({
                    type: "legacy",
                    id: legacyReport._id,
                    report_date: legacyReport.report_date,
                    course_name: legacyReport.course.name,
                    instructor_name: legacyReport.instructor_name,
                    comments: legacyReport.instructor_comments,
                    next_recommended: legacyReport.next_recommended,
                    status: "Completed",
                  })),

                  // Normalize new reports
                  ...(reportData || []).map((newReport) => ({
                    type: "new",
                    id: newReport._id,
                    report_date: newReport.date_created,
                    course_name: newReport.class?.course?.name || "N/A",
                    instructor_name: newReport.instructor?.name || "N/A",
                    comments: newReport.instructor_comment,
                    next_recommended:
                      newReport.next_recommended_course?.name || "N/A",
                    status: newReport.status,
                  })),
                ]
                  .sort(
                    (a, b) => new Date(b.report_date) - new Date(a.report_date)
                  ) // Sort by report_date
                  .map((report, index) => (
                    <li key={index}>
                      <div className="course-container">
                        <div className="course-info">
                          <b>Course:</b> {report.course_name}
                          <br />
                          <b>Instructor:</b> {report.instructor_name}
                          <br />
                          <b>
                            Report Date:{" "}
                            {new Date(report.report_date).toLocaleDateString()}
                          </b>
                        </div>
                        <div className="report-item">
                          {["Ready", "Completed"].includes(report.status) ? (
                            <button
                              className="download-report-button"
                              onClick={() =>
                                report.type === "legacy"
                                  ? handleDownloadReportCard(report.id)
                                  : handleDownloadNewReport(report.id)
                              }
                            >
                              {report.type === "legacy"
                                ? "Download Report Card"
                                : "Download Report Card"}
                            </button>
                          ) : (
                            <span className="report-status-text">
                              {report.status === "Draft"
                                ? "Draft - Not ready for download"
                                : "Reviewing - Pending approval"}
                            </span>
                          )}
                        </div>
                      </div>

                      <hr />
                      {report.comments || "No comments provided"}
                      <br />
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No reports found for this student.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentSearch;
