import React, { useEffect, useState } from "react";
import { fetchReports } from "../../api/reportApi";
import ReusableModal from "../General/ReusableModal";
import ReviewReportCard from "./ReviewReportCard";
import { submitReportCard } from "../../api/reportApi";
import { generateReportPDF } from "../../pdf/generateReport";
import "./ManageReportCards.css";

const ManageReportCards = () => {
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]); // Holds sorted and filtered reports
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [season, setSeason] = useState("2024-25");
  const [status, setStatus] = useState("Reviewing");
  const [orderBy, setOrderBy] = useState("Class");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [refresh, setRefresh] = useState(false);

  // Fetch reports initially or when season/status changes
  useEffect(() => {
    const getReports = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchReports({ season, status });
        setReports(data);
        setFilteredReports(sortReports(data, "Class")); // Apply initial sorting
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getReports();
  }, [season, status, refresh]);

  // Apply sorting when orderBy changes
  useEffect(() => {
    setFilteredReports(sortReports(reports, orderBy));
  }, [orderBy, reports]);

  const handleSeasonChange = (event) => setSeason(event.target.value);
  const handleStatusChange = (event) => setStatus(event.target.value);
  const handleOrderByChange = (event) => setOrderBy(event.target.value);

  const sortReports = (reports, criterion) => {
    if (!reports || reports.length === 0) return reports;

    const sortedReports = [...reports]; // Clone the array to avoid mutating state
    switch (criterion) {
      case "Class":
        return sortedReports.sort((a, b) =>
          a.class.class_code.localeCompare(b.class.class_code)
        );
      case "Instructor":
        return sortedReports.sort((a, b) =>
          a.instructor.name.localeCompare(b.instructor.name)
        );
      case "Course":
        return sortedReports.sort((a, b) =>
          a.class.course.name.localeCompare(b.class.course.name)
        );
      case "Date Created":
        return sortedReports.sort(
          (a, b) => new Date(a.date_created) - new Date(b.date_created)
        );
      default:
        return reports;
    }
  };

  const handleReportClick = (report) => {
    setModalContent(() => (
      <ReviewReportCard report={report} onClose={handleCloseModal} />
    ));
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    setTimeout(() => setRefresh(!refresh), 250);
  };

  const downloadReports = () => {
    const userConfirmed = window.confirm(
      'This will download all Report Cards in the "Ready" state and set them to the "Completed" state.\n\nMake sure you keep track of downloaded Report Cards to make sure they are sent to the customers since the "Completed" state is for Report Cards that have been sent, via email or otherwise.\n\nAre you sure you want to continue?'
    );

    if (userConfirmed) {
      // User clicked "Yes" (OK)
      console.log("User chose to proceed");
      console.log(reports);
      reports.forEach((report) => {
        console.log(report);
        generateReportPDF(report);
        let reportToUpdate = { ...report };
        reportToUpdate.status = "Completed";
        reportToUpdate.templateID = reportToUpdate.template._id;
        reportToUpdate.studentId = reportToUpdate.student._id;
        reportToUpdate.classId = reportToUpdate.class._id;
        reportToUpdate.instructorId = reportToUpdate.instructor._id;
        reportToUpdate.comments = reportToUpdate.instructor_comment;
        submitReportCard(reportToUpdate);
      });
      setTimeout(() => setRefresh(!refresh), 250);
    }
  };

  return (
    <div className="manage-report-cards-container">
      <h1 className="manage-report-cards-header">Manage Report Cards</h1>

      <div className="filter-section">
        <span className="report-count">
          <p>{filteredReports.length} report cards found</p>
          {status === "Ready" && filteredReports.length > 0 && (
            <button onClick={downloadReports}>Download</button>
          )}
        </span>

        <div>
          <label>
            Season:
            <select value={season} onChange={handleSeasonChange}>
              <option value="2024-25">2024-25</option>
            </select>
          </label>

          <label style={{ marginLeft: "10px" }}>
            Status:
            <select value={status} onChange={handleStatusChange}>
              <option value="">All</option>
              <option value="Draft">Draft</option>
              <option value="Reviewing">Reviewing</option>
              <option value="Ready">Ready</option>
              <option value="Completed">Completed</option>
            </select>
          </label>

          <label style={{ marginLeft: "10px" }}>
            Order By:
            <select value={orderBy} onChange={handleOrderByChange}>
              <option value="Class">Class</option>
              <option value="Instructor">Instructor</option>
              <option value="Course">Course</option>
              <option value="Date Created">Date Created</option>
            </select>
          </label>
        </div>
      </div>

      {loading ? (
        <p className="loading">Loading reports...</p>
      ) : error ? (
        <p className="error-message">Error: {error}</p>
      ) : filteredReports.length > 0 ? (
        <ul className="report-list">
          {filteredReports.map((report) => (
            <li key={report._id} onClick={() => handleReportClick(report)}>
              <div>
                <strong>Student:</strong> {report.student.first_name}{" "}
                {report.student.last_name}
              </div>
              <div>
                <strong>Student Number:</strong> {report.student.student_num}
              </div>
              <div>
                <strong>Instructor:</strong> {report.instructor.name}
              </div>
              <div>
                <strong>Course:</strong> {report.class.course.name}{" "}
              </div>
              <div>
                <strong>Class:</strong> {report.class.class_code}{" "}
              </div>
              <div>
                <strong>Status:</strong> {report.status}
              </div>
              <div>
                <strong>Date Created:</strong>{" "}
                {new Date(report.date_created).toLocaleDateString()}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-reports">No reports found for the selected filters.</p>
      )}

      {/* Reusable Modal */}
      <ReusableModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        content={modalContent}
      />
    </div>
  );
};

export default ManageReportCards;
