// src/components/StudentView.js
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";

import { fetchStudentByNumber } from "../../api/studentApi";
import { fetchClassesByStudentId } from "../../api/classApi";
import {
  fetchLegacyReportCardTemplates,
  fetchReport,
} from "../../api/reportApi";
import CustomerView from "../Customers/CustomerView";
import { findCustomerById } from "../../api/customerApi";
import { MdPersonSearch } from "react-icons/md";
import "./StudentView.css";
import { generateLegacyReportCardPDF } from "../../pdf/generateLegacyReportCard"; // Import the PDF generation function
import { generateReportPDF } from "../../pdf/generateReport"; // Import the PDF generation function

const StudentView = ({ studentNum, onClose }) => {
  const { userRole } = useContext(UserContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [classesData, setClassesData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [reportCardTemplates, setReportCardTemplates] = useState([]);
  const [customerData, setCustomerData] = useState(null);

  const handleBackToList = () => {
    setSelectedCustomer(null);
  };

  const handleCustomerSelect = (id) => {
    setSelectedCustomer(id);
  };

  useEffect(() => {
    const getStudent = async () => {
      try {
        const data = await fetchStudentByNumber(studentNum);
        console.log(data);
        setStudentData(data);
        const classes = await fetchClassesByStudentId(data._id);
        setClassesData(classes);
        const templates = await fetchLegacyReportCardTemplates();
        setReportCardTemplates(templates);
        const reports = await fetchReport(data._id);
        setReportData(reports);
        console.log(data.customer_num);
        const customer = await findCustomerById(data.customer_num);
        console.log(customer);
        setCustomerData(customer);
      } catch (error) {
        setStudentData(null);
        setClassesData([]);
        setReportData([]);
      }
    };
    getStudent();
  }, [studentNum]);

  // Function to handle PDF generation
  const handleDownloadReportCard = (reportCardID) => {
    const reportCard = studentData.report_cards.find(
      (rc) => rc._id === reportCardID
    );
    const matchedTemplate = reportCardTemplates.find(
      (template) => template.course._id === reportCard.course._id
    );
    if (matchedTemplate) {
      generateLegacyReportCardPDF(reportCard, matchedTemplate); // Generate the PDF
    } else {
      console.error("No matching template found for the report card.");
    }
  };

  const handleDownloadNewReport = (reportCardID) => {
    const reportCard = reportData.find((rc) => rc._id === reportCardID);
    generateReportPDF(reportCard);
    console.log(reportCard);
  };

  return (
    <div className="student-view-container">
      {selectedCustomer === null ? (
        <div>
          <button onClick={onClose}>Close</button>
          <hr></hr>
          {studentData && (
            <div className="student-view-info">
              <div className="row">
                <div className="col-md-6 col-12">
                  <h3>
                    Student Name: {studentData.first_name}{" "}
                    {studentData.last_name}
                  </h3>
                  <p>
                    <strong>Student Number:</strong> {studentData.student_num}
                  </p>

                  {userRole === 4 &&
                    customerData &&
                    customerData.CustomerID && (
                      <p>
                        <strong>Customer Number:</strong>{" "}
                        {customerData.CustomerID}
                      </p>
                    )}

                  {studentData.next_recommended_course &&
                    studentData.next_recommended_course.length > 0 && (
                      <div className="next-recommended">
                        <strong>Next Course Recommendations:</strong>
                        <ul>
                          {studentData.next_recommended_course.map(
                            (course, index) => (
                              <li key={index}>{course.name}</li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                </div>
                {userRole === 8 && (
                  <div className="col-md-6 col-12">
                    {customerData &&
                      customerData.primary_first_name &&
                      customerData.primary_last_name && (
                        <div>
                          <h4>
                            Parent Name: {customerData.primary_first_name}{" "}
                            {customerData.primary_last_name}{" "}
                            <MdPersonSearch
                              className="action-icon"
                              title="Open Customer Page"
                              onClick={() =>
                                handleCustomerSelect(customerData.CustomerID)
                              }
                            />
                          </h4>
                        </div>
                      )}

                    {customerData && customerData.CustomerID && (
                      <p className="customer-item">
                        <strong>Customer Number:</strong>{" "}
                        {customerData.CustomerID}
                      </p>
                    )}

                    {customerData && customerData.cell_phone && (
                      <p className="customer-item">
                        <strong>Customer Cell:</strong>{" "}
                        {customerData.cell_phone}
                      </p>
                    )}
                    {customerData && customerData.email_address && (
                      <p className="customer-item">
                        <strong>Customer Email:</strong>{" "}
                        {customerData.email_address}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <hr />
              <div className="courses-taken">
                <h4>Courses Taken:</h4>
                {classesData && classesData.length > 0 ? (
                  <ul>
                    {classesData
                      .sort((a, b) => {
                        if (a.season > b.season) return -1;
                        if (a.season < b.season) return 1;
                        return 0;
                      })
                      .map((courseClass, index) => (
                        <li key={index}>
                          <div className="row justify-content-evenly">
                            <div className="col-md-4 col-12">
                              <strong>Course Name:</strong>{" "}
                              {courseClass.course?.name || "Not Assigned"}
                            </div>
                            <div className="col-md-4 col-12">
                              <strong>Instructor:</strong>{" "}
                              {courseClass.instructor?.name || "Not Assigned"}
                            </div>
                            <div className="col-md-3 col-12">
                              <strong>Season:</strong>{" "}
                              {courseClass.season || "Not Assigned"}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                ) : (
                  <p>No courses found for this student.</p>
                )}
              </div>
              <hr />
              <div className="student-comments">
                <h4>Student Comments:</h4>
                {studentData.student_comments &&
                studentData.student_comments.length > 0 ? (
                  <ul>
                    {studentData.student_comments
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map((comment, index) => (
                        <li key={index}>
                          {comment.comment} (Date:{" "}
                          {new Date(comment.date).toLocaleDateString()} )
                        </li>
                      ))}
                  </ul>
                ) : (
                  <p>No comments found for this student.</p>
                )}
              </div>
              <hr />
              <div className="instructor-comments">
                <h4>Report Cards:</h4>
                {(studentData.report_cards &&
                  studentData.report_cards.length > 0) ||
                (reportData && reportData.length > 0) ? (
                  <ul>
                    {[
                      // Normalize legacy report cards
                      ...(studentData.report_cards || []).map(
                        (legacyReport) => ({
                          type: "legacy",
                          id: legacyReport._id,
                          report_date: legacyReport.report_date,
                          course_name: legacyReport.course.name,
                          instructor_name: legacyReport.instructor_name,
                          comments: legacyReport.instructor_comments,
                          next_recommended: legacyReport.next_recommended,
                          status: "Completed",
                        })
                      ),

                      // Normalize new reports
                      ...(reportData || []).map((newReport) => ({
                        type: "new",
                        id: newReport._id,
                        report_date: newReport.date_created,
                        course_name: newReport.class?.course?.name || "N/A",
                        instructor_name: newReport.instructor?.name || "N/A",
                        comments: newReport.instructor_comment,
                        next_recommended:
                          newReport.next_recommended_course?.name || "N/A",
                        status: newReport.status,
                      })),
                    ]
                      .sort(
                        (a, b) =>
                          new Date(b.report_date) - new Date(a.report_date)
                      ) // Sort by report_date
                      .map((report, index) => (
                        <li key={index}>
                          <div className="course-container">
                            <div className="course-info">
                              <b>Course:</b> {report.course_name}
                              <br />
                              <b>Instructor:</b> {report.instructor_name}
                              <br />
                              <b>
                                Report Date:{" "}
                                {new Date(
                                  report.report_date
                                ).toLocaleDateString()}
                              </b>
                            </div>
                            <div className="report-item">
                              {["Ready", "Completed"].includes(
                                report.status
                              ) ? (
                                <button
                                  className="download-report-button"
                                  onClick={() =>
                                    report.type === "legacy"
                                      ? handleDownloadReportCard(report.id)
                                      : handleDownloadNewReport(report.id)
                                  }
                                >
                                  {report.type === "legacy"
                                    ? "Download Report Card"
                                    : "Download Report Card"}
                                </button>
                              ) : (
                                <span className="report-status-text">
                                  {report.status === "Draft"
                                    ? "Draft - Not ready for download"
                                    : "Reviewing - Pending approval"}
                                </span>
                              )}
                            </div>
                          </div>

                          <hr />
                          {report.comments || "No comments provided"}
                          <br />
                        </li>
                      ))}
                  </ul>
                ) : (
                  <p>No reports found for this student.</p>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <CustomerView
            customerID={selectedCustomer}
            onBack={handleBackToList}
          />
        </div>
      )}
    </div>
  );
};

export default StudentView;
