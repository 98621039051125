// src/components/CustomerSearch.js
import React, { useState } from "react";
import "./CustomerSearch.css"; // CSS specific to this component

const CustomerSearch = ({ onSearch }) => {
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [error, setError] = useState("");

  const handleSearch = async () => {
    const filters = {
      customerId,
      customerName,
      customerLastName,
      customerPhone,
      customerEmail,
    };

    onSearch(filters);
    setError(""); // Clear errors after successful search
  };

  return (
    <div className="customer-search-container">
      <h2>Search Customer</h2>
      <br></br>
      <div className="row">
        <label
          className="customer-search-input col-md-3 col-12"
          htmlFor="customernum"
        >
          Customer Number
        </label>
        <input
          id="customernumber"
          type="text"
          placeholder="Customer Number"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
          className="customer-search-input col-md-4 col-12"
        />
        <div className="customer-search-input col-md-5 col-12">
          <i>Customer Number is unique</i>
        </div>
      </div>
      <hr></hr>
      <div className="row">
        <input
          type="text"
          placeholder="First Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          className="customer-search-input col-md-4 col-12"
        />
        <input
          type="text"
          placeholder="Last Name"
          value={customerLastName}
          onChange={(e) => setCustomerLastName(e.target.value)}
          className="customer-search-input col-md-4 col-12"
        />
      </div>
      <br></br>
      <div className="row">
        <input
          type="text"
          placeholder="Phone Number"
          value={customerPhone}
          onChange={(e) => setCustomerPhone(e.target.value)}
          className="customer-search-input col-md-4 col-12"
        />
        <input
          type="text"
          placeholder="Email"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
          className="customer-search-input col-md-4 col-12"
        />
      </div>

      <br></br>
      <button className="customer-search-button" onClick={handleSearch}>
        Search
      </button>
      {error && <p className="customer-search-error">{error}</p>}
    </div>
  );
};

export default CustomerSearch;
