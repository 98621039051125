import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Utility function to load an image and convert it to base64
const loadImageToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response); // Converts the image to base64
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};

export const generateReportPDF = async (report) => {
  console.log(report);
  const companyLogo = await loadImageToBase64("/logo.png");

  // Group the learning goals by type
  const groupGoals = (goals) =>
    goals.reduce((groups, goal) => {
      const { category, name, achieved, expected } = goal;
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push({ name, achieved, expected });
      return groups;
    }, {});

  const knowledgeGoals = groupGoals(report.knowledge_goals);
  const applicationGoals = groupGoals(report.application_goals);
  const skillGoals = report.skill_goals.map((skill) => ({
    name: skill.name,
    achieved: skill.achieved,
    expected: skill.expected,
  }));

  const generateGoalsTable = (goals, title) => {
    return [
      { text: title, style: "subheader", margin: [0, 10] },
      ...Object.entries(goals).map(([category, items]) => [
        //{ text: `Category: ${category}`, bold: true, margin: [0, 5] },
        {
          table: {
            headerRows: 1,
            widths: ["40%", "30%", "30%"],
            body: [
              [
                { text: "Name", bold: true },
                { text: "Expected", bold: true },
                { text: "Achieved", bold: true },
              ],
              ...items.map((item) => [
                item.name,
                item.expected || "N/A",
                item.achieved || "N/A",
              ]),
            ],
          },
          layout: "lightHorizontalLines",
        },
      ]),
    ].flat();
  };

  const docDefinition = {
    content: [
      // Header with Logo and Title
      {
        columns: [
          {
            stack: [
              { text: "Report Card", style: "header" },
              {
                text: `Student: ${report.student.first_name} ${report.student.last_name}`,
                margin: [0, 2],
              },
              { text: `Instructor: ${report.instructor.name}`, margin: [0, 2] },
              {
                text: `Course: ${report.class.course.name}`,
                margin: [0, 2],
              },
            ],
            width: "70%",
          },
          {
            stack: [
              {
                image: companyLogo,
                width: 100,
                alignment: "right",
              },
              {
                text: `Date: ${new Date(
                  report.date_created
                ).toLocaleDateString()}`,
                margin: [0, 4],
                alignment: "right",
              },
              {
                text: `Season: ${report.class.season}`,
                margin: [0, 2],
                alignment: "right",
              },
            ],
          },
        ],
      },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 10],
      },
      { text: "Course Summary", style: "subheader", margin: [0, 10] },
      { text: report.template.summary, margin: [0, 5], fontSize: 12 },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 10],
      },
      ...generateGoalsTable(knowledgeGoals, "Knowledge Goals"),
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 5],
      },
      ...generateGoalsTable(applicationGoals, "Application Goals"),
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 5],
      },
      { text: "Skill Goals", style: "subheader", margin: [0, 10] },
      {
        table: {
          headerRows: 1,
          widths: ["40%", "30%", "30%"],
          body: [
            [
              { text: "Name", bold: true },
              { text: "Achieved", bold: true },
              { text: "Expected", bold: true },
            ],
            ...skillGoals.map((goal) => [
              goal.name,
              goal.achieved || "N/A",
              goal.expected || "N/A",
            ]),
          ],
        },
        layout: "lightHorizontalLines",
        margin: [0, 5],
      },

      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 10],
      },
      // Legend
      { text: "Legend", style: "subheader", margin: [0, 20] },
      {
        columns: [
          // Knowledge Legend
          {
            stack: [
              { text: "Grade - Knowledge", style: "subheader" },
              {
                ul: [
                  "1 - No Knowledge: The student is unable to define or identify the topic’s key concepts.",
                  "2 - Limited Knowledge: The student knows a few terms or concepts but struggles with accuracy.",
                  "3 - Basic Knowledge: The student can recall essential definitions but lacks depth in understanding.",
                  "4 - Developing Knowledge: The student demonstrates a solid understanding but may miss minor details.",
                  "5 - Proficient Knowledge: The student fully understands and accurately defines all key concepts.",
                ],
              },
            ],
            width: "50%",
          },
          // Application Legend
          {
            stack: [
              { text: "Grade - Application", style: "subheader" },
              {
                ul: [
                  "1 - No Application: The student is unable to apply the concept or recognize its relevance.",
                  "2 - Limited Application: The student has difficulty recognizing when or how to apply the concept.",
                  "3 - Basic Application: The student understands when to apply the concept but struggles with execution.",
                  "4 - Developing Application: The student usually applies the concept correctly but may need occasional help.",
                  "5 - Proficient Application: The student consistently applies the concept correctly in various contexts.",
                ],
              },
            ],
            width: "50%",
          },
        ],
      },

      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 10],
      },
      { text: "Instructor Comments", style: "subheader", margin: [0, 10] },
      {
        text: report.instructor_comment || "No comments provided",
        margin: [0, 5],
        fontSize: 12,
      },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 10],
      },
      { text: "Next Recommended Course", style: "subheader", margin: [0, 10] },
      {
        text: report.next_recommended_course?.name || "No recommendations",
        margin: [0, 4],
        fontSize: 14,
      },
    ],
    footer: (currentPage, pageCount) => {
      return {
        columns: [
          {
            text: `Real Programming 4 Kids\n255 Glenlake Ave #1511, Toronto, ON, M6P 1G2 / 1-877-307-3456 (toll free) / 416-469-9676 (Toronto)`,
            alignment: "center",
            fontSize: 10,
          },
        ],
        margin: [0, 0, 0, 20],
      };
    },
    styles: {
      header: { fontSize: 22, bold: true },
      subheader: { fontSize: 14, bold: true },
    },
  };

  pdfMake
    .createPdf(docDefinition)
    .download(
      `${report.student.first_name}_${report.student.last_name}_${report.class.season}_${report.student.customer_num}_report_card.pdf`
    );
};
