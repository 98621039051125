import React, { useState } from "react";
import CustomerView from "./CustomerView";
import CustomerList from "./CustomerList";
import CustomerSearch from "./CustomerSearch";

const Customers = () => {
  const [customerID, setCustomerID] = useState(null);
  const [searchfilters, setSearchFilters] = useState({}); // State to hold search filters

  const handleCustomerSearch = (filters) => {
    setSearchFilters(filters); // Update filters state
  };

  const handleBackToList = () => {
    setCustomerID(null);
  };

  const handleCustomerSelect = (id) => {
    setCustomerID(id);
  };

  return (
    <div className="customers-container">
      {customerID ? (
        <div>
          <CustomerView customerID={customerID} onBack={handleBackToList} />
        </div>
      ) : (
        <div>
          <CustomerSearch onSearch={handleCustomerSearch} />
          <CustomerList
            onCustomerSelect={handleCustomerSelect}
            searchfilters={searchfilters}
          />
        </div>
      )}
    </div>
  );
};

export default Customers;
