import React, { useEffect, useState, useContext } from "react";
import {
  fetchReportTemplate,
  fetchReportLevels,
  submitReportCard,
} from "../../api/reportApi";
import { fetchStudentByNumber } from "../../api/studentApi";
import { fetchCourses } from "../../api/courseApi";
import { updateMidseasonOrReport } from "../../api/classApi";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ReportCard.css";

const ReportCard = ({ course, student, classItem, onClose }) => {
  const { userID } = useContext(UserContext);
  const [reportTemplate, setReportTemplate] = useState(null);
  const [reportLevels, setReportLevels] = useState(null);
  const [knowledgeGrades, setKnowledgeGrades] = useState({});
  const [applicationGrades, setApplicationGrades] = useState({});
  const [skillGrades, setSkillGrades] = useState({});
  const [comments, setComments] = useState("");
  const [availableCourses, setAvailableCourses] = useState([]);
  const [nextRecommendedCourse, setNextRecommendedCourse] = useState("");
  const [studentObject, setStudentObject] = useState(null);
  const [showReference, setShowReference] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const studentObj = await fetchStudentByNumber(student.student_num);
        setStudentObject(studentObj);

        const template = await fetchReportTemplate(course._id);
        setReportTemplate(template);

        const levels = await fetchReportLevels();
        setReportLevels(levels);

        const courses = await fetchCourses();
        setAvailableCourses(
          courses
            .filter((c) => c.active)
            .filter((c) => c.level !== "FREE TRIAL")
            .sort((a, b) => a.name.localeCompare(b.name))
        );
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, [course._id, student.student_num]);

  const toggleReference = () => {
    setShowReference(!showReference);
  };

  const handleGradeChange = (goalType, goalName, value) => {
    if (goalType === "knowledge") {
      setKnowledgeGrades({ ...knowledgeGrades, [goalName]: value });
    } else if (goalType === "application") {
      setApplicationGrades({ ...applicationGrades, [goalName]: value });
    } else if (goalType === "skill") {
      setSkillGrades({ ...skillGrades, [goalName]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Prepare report data for submission
    const reportData = {
      templateID: reportTemplate._id,
      studentId: student._id,
      classId: classItem._id,
      instructorId: userID,
      knowledge_goals: Object.keys(knowledgeGrades).map((goalName) => ({
        name: goalName,
        achieved: knowledgeGrades[goalName],
        expected: reportTemplate.knowledge_goals.find(
          (goal) => goal.name === goalName
        )?.expected,
      })),
      application_goals: Object.keys(applicationGrades).map((goalName) => ({
        name: goalName,
        achieved: applicationGrades[goalName],
        expected: reportTemplate.application_goals.find(
          (goal) => goal.name === goalName
        )?.expected,
      })),
      skill_goals: Object.keys(skillGrades).map((goalName) => ({
        name: goalName,
        achieved: skillGrades[goalName],
        expected: reportTemplate.skill_goals.find(
          (goal) => goal.name === goalName
        )?.expected,
      })),
      comments,
      next_recommended_course: nextRecommendedCourse,
      status: "Reviewing",
    };

    try {
      // Call the API to submit the report card
      await submitReportCard(reportData);
      await updateMidseasonOrReport(classItem._id, student._id, "reportCard");
      // Show success message and close modal
      toast.success("Report card submitted successfully!");
      onClose();
    } catch (error) {
      toast.error("Error submitting report card: " + error.message);
      console.error("Error submitting report card: ", error);
    }
  };

  const groupedGoals = {};
  if (
    reportTemplate &&
    reportTemplate.knowledge_goals &&
    reportTemplate.application_goals
  ) {
    reportTemplate.knowledge_goals.forEach((goal) => {
      if (!groupedGoals[goal.category]) {
        groupedGoals[goal.category] = {
          knowledge: [],
          application: [],
        };
      }
      groupedGoals[goal.category].knowledge.push(goal);
    });

    reportTemplate.application_goals.forEach((goal) => {
      if (!groupedGoals[goal.category]) {
        groupedGoals[goal.category] = {
          knowledge: [],
          application: [],
        };
      }
      groupedGoals[goal.category].application.push(goal);
    });
  }

  if (!reportTemplate || !reportLevels) {
    return <div>Loading...</div>;
  }

  return (
    <div className="create-report-card-form">
      <h2>Create Report Card</h2>

      <div className="report-header">
        {student ? (
          <>
            <strong>Student Name:</strong> {student.first_name}{" "}
            {student.last_name}
          </>
        ) : (
          <strong>Loading...</strong>
        )}
        <br />
        <strong>Course:</strong> {course.name}
        <br />
        <strong>Class Code:</strong> {classItem.class_code}
      </div>
      {/* Reference Section */}
      <div className="reference-section">
        <button
          type="button"
          className="reference-btn"
          onClick={toggleReference}
        >
          {showReference ? "Hide Grading Reference" : "Show Grading Reference"}
        </button>
        {showReference && (
          <div className="grading-reference">
            <h3>Grading Reference</h3>
            <h4>Knowledge Grades</h4>
            <ul>
              <li>
                <strong>1 - No Knowledge:</strong> The student is unable to
                define or identify the topic’s key concepts.
              </li>
              <li>
                <strong>2 - Limited Knowledge:</strong> The student knows a few
                terms or concepts but struggles with accuracy.
              </li>
              <li>
                <strong>3 - Basic Knowledge:</strong> The student can recall
                essential definitions but lacks depth in understanding.
              </li>
              <li>
                <strong>4 - Developing Knowledge:</strong> The student
                demonstrates a solid understanding but may miss minor details.
              </li>
              <li>
                <strong>5 - Proficient Knowledge:</strong> The student fully
                understands and accurately defines all key concepts.
              </li>
            </ul>
            <h4>Application Grades</h4>
            <ul>
              <li>
                <strong>1 - No Application:</strong> The student is unable to
                apply the concept or recognize its relevance.
              </li>
              <li>
                <strong>2 - Limited Application:</strong> The student has
                difficulty recognizing when or how to apply the concept.
              </li>
              <li>
                <strong>3 - Basic Application:</strong> The student understands
                when to apply the concept but struggles with execution.
              </li>
              <li>
                <strong>4 - Developing Application:</strong> The student usually
                applies the concept correctly but may need occasional help.
              </li>
              <li>
                <strong>5 - Proficient Application:</strong> The student
                consistently applies the concept correctly in various contexts.
              </li>
            </ul>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        {/* Grouped Goals by Category */}
        {Object.keys(groupedGoals).map((category, index) => (
          <div key={index} className="report-section">
            <h3>{category}</h3>
            {groupedGoals[category].knowledge.map((goal, goalIndex) => (
              <div key={goalIndex} className="report-row">
                <span className="goal-name">{goal.name}</span>
                <select
                  className="goal-select"
                  value={knowledgeGrades[goal.name] || ""}
                  onChange={(e) =>
                    handleGradeChange("knowledge", goal.name, e.target.value)
                  }
                  required
                >
                  <option value="">Select Knowledge Grade</option>
                  {reportLevels.knowledge.map((level, levelIndex) => (
                    <option key={levelIndex} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
                <span className="expected-grade">
                  Expected:<br></br>
                  {goal.expected}
                </span>

                {/* Application Goal (matching name) */}
                <select
                  className="goal-select"
                  value={applicationGrades[goal.name] || ""}
                  onChange={(e) =>
                    handleGradeChange("application", goal.name, e.target.value)
                  }
                  required
                >
                  <option value="">Select Application Grade</option>
                  {reportLevels.application.map((level, levelIndex) => (
                    <option key={levelIndex} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
                <span className="expected-grade">
                  Expected:<br></br>
                  {groupedGoals[category].application[goalIndex]?.expected}
                </span>
              </div>
            ))}
          </div>
        ))}
        {/* Skill Goals */}
        <div className="report-section">
          <h3>General Computer Skills</h3>
          {reportTemplate.skill_goals.map((goal, index) => (
            <div key={index} className="report-row">
              <span className="goal-name">{goal.name}</span>
              <select
                className="goal-select"
                value={skillGrades[goal.name] || ""}
                onChange={(e) =>
                  handleGradeChange("skill", goal.name, e.target.value)
                }
                required
              >
                <option value="">Select Skill Grade</option>
                {reportLevels.skill.map((level, levelIndex) => (
                  <option key={levelIndex} value={level}>
                    {level}
                  </option>
                ))}
              </select>
              <span className="expected-grade">Expected: {goal.expected}</span>
            </div>
          ))}
        </div>
        {/* Instructor Comments */}
        <div className="report-section">
          <h3>Instructor Comments</h3>
          <textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            rows="4"
            placeholder="Enter instructor comments here"
            className="comments-textarea"
          />
        </div>
        {/* Next Recommended Course */}

        <div className="report-section">
          <h3>Next Recommended Course</h3>
          <div className="row">
            <div className="col-md-6 col-12">
              <select
                className="goal-select"
                value={nextRecommendedCourse}
                onChange={(e) => setNextRecommendedCourse(e.target.value)}
                required
              >
                <option value="">Select Next Course</option>
                {availableCourses.map((course) => (
                  <option key={course._id} value={course._id}>
                    {course.name} - {course.level}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-6 col-12">
              <div>
                <b>Courses already taken by {studentObject.first_name}:</b>
              </div>
              {studentObject.completed_courses.map((course, index) => (
                <div key={index}>{course.name}</div>
              ))}
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Submit Report
        </button>
      </form>
    </div>
  );
};

export default ReportCard;
