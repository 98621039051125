import React, { useEffect, useState } from "react";
import { fetchReportLevels, submitReportCard } from "../../api/reportApi";
import { fetchCourses } from "../../api/courseApi";
import { fetchStudentsByID, saveStudent } from "../../api/studentApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ReportCard.css";
import "./ReviewReportCard.css";

const ReviewReportCard = ({ report, onClose }) => {
  const [reportLevels, setReportLevels] = useState(null);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [knowledgeGrades, setKnowledgeGrades] = useState({});
  const [applicationGrades, setApplicationGrades] = useState({});
  const [skillGrades, setSkillGrades] = useState({});
  const [comments, setComments] = useState(report.instructor_comment || "");
  const [nextRecommendedCourse, setNextRecommendedCourse] = useState(
    report.next_recommended_course || ""
  );
  const [status, setStatus] = useState(report.status || "");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setNextRecommendedCourse(report.next_recommended_course._id);

        const levels = await fetchReportLevels();
        setReportLevels(levels);

        const courses = await fetchCourses(); // Fetch all active courses
        setAvailableCourses(
          courses
            .filter((c) => c.active)
            .filter((c) => c.level !== "FREE TRIAL")
            .sort((a, b) => a.name.localeCompare(b.name))
        );

        // Populate grades with existing values from the report
        const initialKnowledgeGrades = {};
        report.knowledge_goals.forEach(
          (goal) => (initialKnowledgeGrades[goal.name] = goal.achieved)
        );
        setKnowledgeGrades(initialKnowledgeGrades);

        const initialApplicationGrades = {};
        report.application_goals.forEach(
          (goal) => (initialApplicationGrades[goal.name] = goal.achieved)
        );
        setApplicationGrades(initialApplicationGrades);

        const initialSkillGrades = {};
        report.skill_goals.forEach(
          (goal) => (initialSkillGrades[goal.name] = goal.achieved)
        );
        setSkillGrades(initialSkillGrades);
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchData();
  }, [report]);

  const handleGradeChange = (goalType, goalName, value) => {
    if (goalType === "knowledge") {
      setKnowledgeGrades({ ...knowledgeGrades, [goalName]: value });
    } else if (goalType === "application") {
      setApplicationGrades({ ...applicationGrades, [goalName]: value });
    } else if (goalType === "skill") {
      setSkillGrades({ ...skillGrades, [goalName]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reportData = {
      templateID: report.template._id,
      studentId: report.student._id,
      classId: report.class._id,
      instructorId: report.instructor._id,
      knowledge_goals: Object.keys(knowledgeGrades).map((goalName) => ({
        name: goalName,
        achieved: knowledgeGrades[goalName],
        expected: report.template.knowledge_goals.find(
          (goal) => goal.name === goalName
        )?.expected,
      })),
      application_goals: Object.keys(applicationGrades).map((goalName) => ({
        name: goalName,
        achieved: applicationGrades[goalName],
        expected: report.template.application_goals.find(
          (goal) => goal.name === goalName
        )?.expected,
      })),
      skill_goals: Object.keys(skillGrades).map((goalName) => ({
        name: goalName,
        achieved: skillGrades[goalName],
        expected: report.template.skill_goals.find(
          (goal) => goal.name === goalName
        )?.expected,
      })),
      comments,
      next_recommended_course: nextRecommendedCourse,
      status,
    };

    try {
      await submitReportCard(reportData);

      if (reportData.status === "Ready") {
        let student = await fetchStudentsByID(reportData.studentId);
        console.log("Before Modification:", student);

        // Create a new copy of the student object
        const updatedStudent = { ...student };

        // Modify the new object
        updatedStudent.next_recommended_course = [nextRecommendedCourse];
        updatedStudent.completed_courses = [
          ...new Set([
            ...(updatedStudent.completed_courses || []),
            report.class.course._id,
          ]),
        ];
        if (!updatedStudent.seasons.includes(report.class.season))
          updatedStudent.seasons.push(report.class.season);
        await saveStudent(updatedStudent);
        console.log("After Modification:", updatedStudent);
      }

      toast.success("Report card updated successfully!");
      onClose();
    } catch (error) {
      toast.error("Error updating report card: " + error.message);
      console.error("Error updating report card: ", error);
    }
  };

  if (!reportLevels || availableCourses.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="create-report-card-form">
      <h2>Review Report Card</h2>
      <div className="row report-heading">
        <h5 className="col-md-6 col-12">
          <b>Student:</b> {report.student.first_name} {report.student.last_name}
        </h5>
        <h5 className="col-md-6 col-12">
          <b>Student Number:</b> {report.student.student_num}
        </h5>
      </div>
      <div className="row report-details">
        <p className="col-md-4 col-12">
          <b>Instructor:</b> {report.instructor.name}
        </p>
        <p className="col-md-4 col-12">
          <b>Season:</b> {report.class.season}
        </p>
        <p className="col-md-4 col-12">
          <b>Class:</b> {report.class.class_code}
        </p>
      </div>
      <br></br>
      <h3>Course: {report.class.course.name}</h3>
      <p>{report.template.summary}</p>
      <br></br>
      <form onSubmit={handleSubmit}>
        {/* Knowledge Goals */}
        <div className="report-section">
          <h3>Knowledge Goals</h3>
          {report.knowledge_goals.map((goal, index) => (
            <div key={index} className="report-row">
              <span className="goal-name">{goal.name}</span>
              <select
                className="goal-select"
                value={knowledgeGrades[goal.name] || ""}
                onChange={(e) =>
                  handleGradeChange("knowledge", goal.name, e.target.value)
                }
                required
              >
                <option value="">Select Knowledge Grade</option>
                {reportLevels.knowledge.map((level, levelIndex) => (
                  <option key={levelIndex} value={level}>
                    {level}
                  </option>
                ))}
              </select>
              <span className="expected-grade">Expected: {goal.expected}</span>
            </div>
          ))}
        </div>

        {/* Application Goals */}
        <div className="report-section">
          <h3>Application Goals</h3>
          {report.application_goals.map((goal, index) => (
            <div key={index} className="report-row">
              <span className="goal-name">{goal.name}</span>
              <select
                className="goal-select"
                value={applicationGrades[goal.name] || ""}
                onChange={(e) =>
                  handleGradeChange("application", goal.name, e.target.value)
                }
                required
              >
                <option value="">Select Application Grade</option>
                {reportLevels.application.map((level, levelIndex) => (
                  <option key={levelIndex} value={level}>
                    {level}
                  </option>
                ))}
              </select>
              <span className="expected-grade">Expected: {goal.expected}</span>
            </div>
          ))}
        </div>

        {/* Skill Goals */}
        <div className="report-section">
          {report.skill_goals.length > 0 && <h3>Skill Goals</h3>}

          {report.skill_goals.map((goal, index) => (
            <div key={index} className="report-row">
              <span className="goal-name">{goal.name}</span>
              <select
                className="goal-select"
                value={skillGrades[goal.name] || ""}
                onChange={(e) =>
                  handleGradeChange("skill", goal.name, e.target.value)
                }
                required
              >
                <option value="">Select Skill Grade</option>
                {reportLevels.skill.map((level, levelIndex) => (
                  <option key={levelIndex} value={level}>
                    {level}
                  </option>
                ))}
              </select>
              <span className="expected-grade">Expected: {goal.expected}</span>
            </div>
          ))}
        </div>

        {/* Instructor Comments */}
        <div className="report-section">
          <h3>Instructor Comments</h3>
          <textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            rows="4"
            placeholder="Enter instructor comments here"
            className="comments-textarea"
          />
        </div>

        {/* Next Recommended Course */}
        <div className="report-section">
          <h3>Next Recommended Course</h3>
          <div className="row">
            <div className="col-md-6 col-12">
              <select
                className="goal-select"
                value={nextRecommendedCourse}
                onChange={(e) => setNextRecommendedCourse(e.target.value)}
                required
              >
                <option value="">Select Next Course</option>
                {availableCourses.map((course) => (
                  <option key={course._id} value={course._id}>
                    {course.name} - {course.level}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 col-12">
              <div>
                <b>Courses already taken by {report.student.first_name}:</b>
              </div>
              {report.student.completed_courses.map((course, index) => (
                <div key={index}>{course.name}</div>
              ))}
            </div>
          </div>
        </div>
        {/* Status */}
        <div className="report-section">
          <h3>Status</h3>
          <select
            className="goal-select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="Draft">Draft</option>
            <option value="Reviewing">Reviewing</option>
            <option value="Ready">Ready</option>
            <option value="Completed">Completed</option>
          </select>
        </div>

        <button type="submit" className="btn btn-primary">
          Update Report
        </button>
      </form>
    </div>
  );
};

export default ReviewReportCard;
