import React, { useState, useEffect, useContext, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import { UserContext } from "./context/UserContext";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import CourseList from "./components/CourseList";
import MarketingQuery from "./components/MarketingQuery";
import UserList from "./components/UserList";
import StudentList from "./components/Students/StudentList";
import StudentSearch from "./components/Students/StudentSearch";
import Customers from "./components/Customers/Customers";
import Login from "./components/Login";
import Register from "./components/Register";
import CreateClass from "./components/Classes/CreateClass";
import DisplayClasses from "./components/Classes/DisplayClasses";
import CreateGeneralReportTemplate from "./components/ReportCards/CreateGeneralReportTemplate";
import CreateReportCardTemplate from "./components/ReportCards/CreateReportCardTemplate";
import HomePage from "./components/Home/Homepage";
import MidSeasonReportForm from "./components/ReportCards/MidSeasonReport";
import ManageReportCards from "./components/ReportCards/ManageReportCards";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initially null
  const { userRole, setUserRole, setUserID, setName } = useContext(UserContext);

  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    setName(null);
    setUserID(null);
    setUserRole(null);
    setIsAuthenticated(false); // Update the state to trigger re-render and navigation
    navigate("/login");
  }, [setName, setUserID, setUserRole, setIsAuthenticated, navigate]);

  // Check if user is logged in on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp < currentTime) {
          // Token is expired
          handleLogout();
        } else {
          // Token is valid
          setIsAuthenticated(true);
          setUserRole(decodedToken.role);
          setUserID(decodedToken.id);
          setName(decodedToken.name);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false); // Ensure this is set if no token exists
    }
  }, [setName, handleLogout, setUserID, setUserRole]);

  const handleLoginSuccess = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsAuthenticated(true);
      setUserID(decodedToken.id);
      setUserRole(decodedToken.role);
      navigate("/");
    }
  };

  // Render loading state until authentication is determined
  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or a spinner, to show while checking auth
  }

  return (
    <div className="App">
      <Header userRole={userRole} onLogout={handleLogout} />
      <ToastContainer />
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/courses" element={<CourseList />} />
            <Route path="/student" element={<StudentSearch />} />
            <Route path="/students" element={<StudentList />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/marketing-query" element={<MarketingQuery />} />
            <Route path="/contacts" element={<div>Contacts</div>} />
            <Route path="/followups" element={<div>Followups</div>} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/createClass" element={<CreateClass />} />
            <Route path="/displayClasses" element={<DisplayClasses />} />
            <Route path="/reportCards" element={<ManageReportCards />} />
            <Route
              path="/createGeneralReportTemplate"
              element={<CreateGeneralReportTemplate />}
            />
            <Route
              path="/createReportCardTemplate"
              element={<CreateReportCardTemplate />}
            />
            <Route
              path="/midSeasonReport/:classId/:studentId"
              element={<MidSeasonReportForm />}
            />
            <Route path="*" element={<div>404 Not Found</div>} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/login"
              element={<Login onLoginSuccess={handleLoginSuccess} />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </div>
  );
};

export default App;
