import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Correct default import
import "./Header.css";

const Header = ({ userRole, onLogout }) => {
  const navigate = useNavigate();

  // Get the user's name from the token stored in localStorage
  const token = localStorage.getItem("token");
  let userName = "User";
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userName = decodedToken.name || "User";
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  // Logout function to clear token and navigate to login
  const handleLogout = () => {
    onLogout(); // Call the onLogout function passed as a prop
    setTimeout(() => {
      navigate("/login"); // Navigate to login after logout
    }, 0);
  };

  return (
    <header className="header">
      <Link to="/">
        <div className="logo">
          <img src="/logo.svg" alt="Logo" />
        </div>
      </Link>
      <div className="welcome-message">Hello, {userName}</div>
      <nav className="nav-links">
        {userRole === 8 && (
          <>
            <Link to="/courses">Courses</Link>
            <Link to="/customers">Customers</Link>
            <Link to="/student">Student Search</Link>
            <Link to="/marketing-query">Marketing Query</Link>
            <Link to="/displayClasses">Classes</Link>
            <Link to="/createClass">New Class</Link>
            <Link to="/reportCards">Report Cards</Link>
          </>
        )}
        {/* Future scaffolding for other roles */}
        {userRole === 4 && (
          <>
            <Link to="/displayClasses">Classes</Link>
          </>
        )}
        <Link to="#" onClick={handleLogout} className="logout-link">
          Logout
        </Link>
      </nav>
    </header>
  );
};

export default Header;
