// api/customerApi.js
const API_URL = process.env.REACT_APP_API_URL;

export const fetchCustomersWithPagination = async (
  page,
  limit,
  filters = {}
) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token is not available");
    }

    // Build query string
    const queryParams = new URLSearchParams({
      page,
      limit,
      ...filters, // Spread filters into query parameters
    }).toString();

    const response = await fetch(`${API_URL}/api/customers?${queryParams}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch customers");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching customers:", error);
  }
};

export const findCustomerById = async (customerId) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token is missing");
    }
    const response = await fetch(
      `${API_URL}/api/customers/find?customerID=${customerId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Customer not found");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};
